/*!

 =========================================================
 * Material Dashboard React - v1.2.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

.ct-grid {
    stroke: #e0e0e0;
    stroke-width: 1px;
    stroke-dasharray: 2px;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
    stroke: rgba(55, 71, 79);
}

.ct-label.ct-horizontal.ct-end {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}

.ct-label {
    color: #e0e0e0;
}

.ct-chart-line .ct-label, .ct-chart-bar .ct-label {
    display: block;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.ct-label {
    fill: #e0e0e0;
    line-height: 1;
}
html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    background-color: #EEEEEE;
    color: #3C4858;
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}

input[type="file"]#fileComprobante {
 width: 0.1px;
 height: 0.1px;
 opacity: 0;
 overflow: hidden;
 position: absolute;
 z-index: -1;
}
label[for="fileComprobante"] {
    color: #fff;
    padding: 8px 16px;
    cursor: pointer;
}

blockquote footer:before, blockquote small:before {
    content: '\2014 \00A0';
}

h1 {
    font-size: 3em;
    line-height: 1.15em;
}

h2 {
    font-size: 2.4em;
}

h3 {
    font-size: 1.825em;
    line-height: 1.4em;
    margin: 20px 0 10px;
}

h4 {
    font-size: 1.3em;
    line-height: 1.4em;
}

h5 {
    font-size: 1.25em;
    line-height: 1.4em;
    margin-bottom: 15px;
}

h6 {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
}

body {
    background-color: #EEEEEE;
    color: #3C4858;
}

blockquote p {
    font-style: italic;
}

body, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}

a {
    color: #ff8b00;
    text-decoration: none;
}

a:hover, a:focus {
    color: #ff8b00;
    text-decoration: none;
}

legend {
    border-bottom: 0;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

*:focus {
    outline: 0;
}

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    outline: 0 !important;
     width: 0.1px;
 height: 0.1px;
 opacity: 0;
 overflow: hidden;
 position: absolute;
 z-index: -1;
}

legend {
    margin-bottom: 20px;
    font-size: 21px;
}

output {
    padding-top: 8px;
    font-size: 14px;
    line-height: 1.42857;
}

label {
    font-size: 14px;
    line-height: 1.42857;
    color: #AAAAAA;
    font-weight: 400;
}

footer {
    padding: 15px 0;
}

footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

footer ul li {
    display: inline-block;
}

footer ul li a {
    color: inherit;
    padding: 15px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
    display: block;
}

footer ul li a:hover {
    text-decoration: none;
}

@media (max-width: 991px) {
    body,
    html {
        position: relative;
        overflow-x: hidden;
    }

    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 0;
        top: 0;
        left: auto;
        right: 260px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
    }
}
.maskProgress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 67, 119, 0.4);
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
}
.maskProgress.active{
    visibility: visible;
    opacity: 1;
}
.maskProgress.desactive{
    visibility: visible;
    opacity: 0;
}

.ct-precios .ct-series.ct-series-a .ct-point, .ct-precios .ct-series.ct-series-a .ct-line {stroke: #212121}
.ct-precios .ct-series.ct-series-b .ct-point, .ct-precios .ct-series.ct-series-b .ct-line {stroke: #4caf50}
.ct-precios .ct-series.ct-series-c .ct-point, .ct-precios .ct-series.ct-series-c .ct-line {stroke: #f44336}

.ct-precios .ct-series.ct-series-1 .ct-point, .ct-precios .ct-series.ct-series-1 .ct-line {stroke: #212121}
.ct-precios .ct-series.ct-series-3 .ct-point, .ct-precios .ct-series.ct-series-3 .ct-line {stroke: #F44336}
.ct-precios .ct-series.ct-series-4 .ct-point, .ct-precios .ct-series.ct-series-4 .ct-line {stroke: #424242}
.ct-precios .ct-series.ct-series-5 .ct-point, .ct-precios .ct-series.ct-series-5 .ct-line {stroke: #616161}
.ct-precios .ct-series.ct-series-7 .ct-point, .ct-precios .ct-series.ct-series-7 .ct-line {stroke: #757575}
.ct-precios .ct-series.ct-series-9 .ct-point, .ct-precios .ct-series.ct-series-9 .ct-line {stroke: #ffeb3b}
.ct-precios .ct-series.ct-series-10 .ct-point, .ct-precios .ct-series.ct-series-10 .ct-line {stroke: #607d8b}
.ct-precios .ct-series.ct-series-11 .ct-point, .ct-precios .ct-series.ct-series-11 .ct-line {stroke: #4CAF50}
.ct-precios .ct-series.ct-series-12 .ct-point, .ct-precios .ct-series.ct-series-12 .ct-line {stroke: #ffeb3b}
.ct-precios .ct-series.ct-series-13 .ct-point, .ct-precios .ct-series.ct-series-13 .ct-line {stroke: #ffc107}

@page {
    size: letter;
    margin: 0.5cm;

    @bottom-right{
        content: "Pagina " counter(page) " de " counter(pages);
        font-family: "Lato", Helvetica, Arial, sans-serif;
        font-size: 9pt;
        line-height: 1.72222;
    }
}

@media print {
    tr.page-break  {
        /*En el pagare sirve
        para que siempre se muestre el div completo
        en una sola pagina y no en diferentes paginas
        si es que la factura llega a crecer*/
        display: inline-block;
        page-break-before: right;
    }
}

#htmlPDF{
    padding: 40px;
    width: 720px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 9pt;
}
#htmlPDF .grid {
    vertical-align: text-top;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 1.72222;
    color: #34495e;
    border: 1px solid #e5e5e5;
    border-spacing: 0px;
}

#htmlPDF .grid thead{
    background: #CCCCCC;
}



#htmlPDF .grid tbody tr td {
    text-align: center;
    vertical-align: middle;
    padding: 0px 1px 0px 1px;
    text-overflow: ellipsis;
}

#htmlPDF .conceptos tbody tr td {
    font-size: 9px;
    border: .5px solid #e5e5e5;
    border-spacing: 0px;
}

#htmlPDF .grid tbody tr th {
    text-align: center;
    vertical-align: middle;
    padding: 0px 1px 0px 1px;
    text-overflow: ellipsis;
    font-weight: bold;
}
#htmlPDF .grid tfoot tr td {
    border: 0px;
}

@-webkit-keyframes selectAnimation {
  from {opacity: 0;transform: scale(0, 0) translateZ(0px);}
  to {opacity: 1;transform: scale(1, 1) translateZ(0px);}
}

@keyframes selectAnimation {
  from {opacity: 0;transform: scale(0, 0) translateZ(0px);}
  to {opacity: 1;transform: scale(1, 1) translateZ(0px);}
}
